import logo from './logo.svg';
import './App.css';
import { Content, fetchOneEntry, isPreviewing } from '@builder.io/sdk-react';
import { useEffect, useState } from 'react';

function App() {
  const [notFound, setNotFound] = useState(false);
  const [content, setContent] = useState(null);
  const BUILDER_PUBLIC_API_KEY = "93b6832e9f3b41318d01d3937c958ce8";
  const pathToLoad = window.location.pathname == "/" ? "/index" : window.location.pathname;

  // get the page content from Builder
  useEffect(() => {
    fetchOneEntry({
      model: 'page',
      apiKey: BUILDER_PUBLIC_API_KEY,
      userAttributes: {
        urlPath: pathToLoad,
      },
    })
      .then(content => {
        if (content) {
          setContent(content);
        }
        setNotFound(!content);
      })
      .catch(err => {
        console.log('something went wrong while fetching Builder Content: ', err);
      });
  }, []);

  // If no page is found, return
  // a 404 page from your code.
  // The following hypothetical
  // <FourOhFour> is placeholder.
  if (notFound && !isPreviewing()) {
    return <h1>Page Not Found</h1>;
  }

  // return the page when found
  return <Content content={content} model="page" apiKey={BUILDER_PUBLIC_API_KEY} />;
}

export default App;
